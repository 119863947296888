import React from "react"
import { Helmet } from "react-helmet"

import Header from "src/components/headers/header"
import Footer from "src/components/footers/footer"

// markup
const NotFoundPage = () => {
  return (
      <div className="bg-white">
        <Helmet>
          <title>Page introuvable</title>
          <meta name="description" content="Erreur 404" />
        </Helmet>
        <header className="sticky top-0 z-50 bg-art-beige">
          <Header />
        </header>

        <div class="relative max-w-7xl mx-auto h-full lg:flex">
          
            <main className="">
              <div class="flex flex-1 overflow-hidden pr-4">
                <div class="flex flex-1 flex-col">
                  <div class="flex flex-1 bg-white overflow-y-auto paragraph">
                    <div className="relative py-16 bg-white overflow-hidden">
                      <div className="relative px-4 sm:px-6 lg:px-8">
                        <div className="text-lg max-w-prose mx-auto">
                          <h1>
                            <span className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
                              Page introuvable
                            </span>
                          </h1>
                          <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
                            <a href="/">Retour à l'accueil</a>
                          </p>
                          
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>

          </div>
        <footer>
          <Footer />
        </footer>
      </div>    
  )
}

export default NotFoundPage
